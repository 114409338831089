import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import axios from "axios";
import { hideLoading, showLoading } from "../../../slices/loading-slice";
import { useDispatch, useSelector } from "react-redux";
import { useAuthUser } from "react-auth-kit";
import { showToast } from "../../../slices/toast-slice";
import { useNavigate, useParams } from "react-router-dom";
import { isEmptyObject } from "jquery";
import { FaArrowLeft } from "react-icons/fa";
import Swal from "sweetalert2";
import GlobalConfirmPasscod from "../../GlobalConfirmPasscod";

const CreateManualCashin = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const authUser = useAuthUser();
    const globalAuthUser = useSelector((state) => state.auth.globalAuthUser);
    const navigate = useNavigate();
    const [companies, setCompanies] = useState([]);
    const [company, setCompany] = useState([]);
    const [params, setParams] = useState({
        'agent_company_id': '',
        'amount':0,
        'transaction_type': 'Cashin',
        'receiver_type': '',
        'phone_number':'',
        'currency':'',
        'processed_by':0,
    });
    const {agent_company_id} = useParams();

    const [allowCashin, setAllowCashin] = useState(false);
    const [ isModalOpen, setIsModalOpen ] = useState(false);

    const handleInput = (e) => {
        e.persist();
        setParams((prevParams) => {
            const updateParams = {...prevParams, [e.target.name]: e.target.value}; 
            return updateParams;
        });
    };

    const openModal = () => {
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
    };

    const initiateCicoRequest = (event) => {
        event.preventDefault();
        if (globalAuthUser?.location?.agent_country?.enable_passcode === '1') {
            openModal();
            return;
        }
        // save the local txn
        saveCicoRequest();
    }
    
    useEffect(() => {
        
        // check if the authenticated user has a valid passcode
        const signature = globalAuthUser?.signature;

        if (globalAuthUser?.location?.agent_country?.enable_passcode === '1') {
            if(!signature) {
                // no signature is set for the user, prompt the user for a new signature
                dispatch(showToast(['danger', t("PASSCODE_ERROR"), t('PASSCODE_NOT_FOUND')]));
                navigate('/create-passcode');
                return;
            }

            if(!signature?.isActive || signature?.isActive == 2) {
                // signature code not activated
                dispatch(showToast(['danger', t("PASSCODE_ERROR"), t("PASSCODE_INACTIVE")]));
                navigate('/activate-passcode');
                return;
            }
        }

        // enable the submit repayment button
        setAllowCashin(true);

        dispatch(showLoading());
        var data = {
            'source':'pos',
            'type': 'cico',
        };
        const headers = {
            'Authorization': authUser().tokenType + ' ' + authUser().token
        };
        axios.post(process.env.REACT_APP_BNB_BASE_URL + '/api/portal/agentCompanies', data,{headers}
        ).then(res => {
            dispatch(hideLoading());
            if(res.data.status === 1){
                console.log('companies');
                console.log(res.data.data);
                if(isEmptyObject(res.data.data)){
                    dispatch( showToast(["danger", t("MOBILE_MONEY"), t("EMPTY_COMPANIES")]));
                }else{
                    //get the corresponding company to display its name  as a title
                    const my_company = res.data.data.filter((cmp => cmp.company_id == agent_company_id))
                    setCompany(my_company);
                    setCompanies(res.data.data);
                }
            }else{
                dispatch( showToast(["danger", t("SERVER_ERROR"), t("ERROR_HAS_OCCURED")]));
            }
        }).catch(error => {
            console.log(error);
            dispatch(hideLoading());
            dispatch( showToast(["danger", t("SERVER_ERROR"), t("ERROR_HAS_OCCURED")]));
        });
    }, []);

    //Insert a new cico record
    function saveCicoRequest(event) {
        Swal.fire({
            title: t('CONFIRMATION'),
            confirmButtonText: t('CONFIRM'),
            confirmButtonColor: '#0d6efd',
            cancelButtonText: t('CANCEL'),
            showCancelButton: true,
            html: t('CASHIN_CONFIRMATION', {amount: params.amount+' '+globalAuthUser?.location.currency, phone: params.phone_number}), 
            }).then((result) => {
                if (result.value !== undefined && result.value === true) {
                    //Confirm button is clicked
                    dispatch(showLoading());
                    var payload = {
                        agent_location_id: globalAuthUser?.location.id,
                        agent_company_id: agent_company_id,
                        amount: params.amount,
                        transaction_type: params.transaction_type,
                        receiver_type: params.receiver_type,
                        phone_number: params.phone_number,
                        currency: globalAuthUser?.location?.currency,
                        processed_by: params.processed_by,
                    };
                    const headers = {
                        'Authorization': authUser().tokenType + ' ' + authUser().token
                    };
                    
                    axios.post(process.env.REACT_APP_BNB_BASE_URL + '/api/portal/saveCicoRequest', payload, {headers})
                    .then(response =>{
                        if (response.data.status === 1) {
                            const btn = document.getElementsByClassName('btn')[0];
                            // Navigate to a new page after a delay
                            btn.setAttribute('disabled','disabled');
                            dispatch( showToast(["success", t("INFORMATION"), t("TRANSACTION_SUBMITTED_SUCCESS")])); 
                            setTimeout(() => {
                                navigate('/mobile-money');
                            }, 1000);
                        }else{
                            dispatch(hideLoading());
                            console.log(response.data);
                            dispatch( showToast(["danger", t("INFORMATION"), t(response.data.message)])); //we don't need the if else here as response.data.message is translatted
                        }
                    }).catch(error => {
                        dispatch(hideLoading());
                        dispatch( showToast(["danger", t("SERVER_ERROR"), t("ERROR_HAS_OCCURED")]));
                    });
                }
            }
        );
        
    }
      
    return (
        <React.Fragment>
            <div style={{ padding: "20px" }}>
                <div className="card">
                    <div className="card-header d-flex justify-content-between">
                        <h5 className="col-sm-4">{t('SEND_MANUAL_CASHIN',{company: company[0]?.company_name})}</h5>
                        <span>
                            <button type='button' className='btn btn-primary' onClick={() => navigate('/mobile-money')}>
                                <FaArrowLeft size={18} fill={'white'} /> &nbsp;
                                {t('BACK')}
                            </button>
                        </span>
                    </div>
                    
                    <div className="card-body">
                        <form id="my-form" onSubmit={initiateCicoRequest}> 
                            <div className="form-group row">
                                <div className="col-md-6 mb-3">
                                    <label className="" htmlFor="amount">{t('AMOUNT')} <span className="text-danger">*</span></label>
                                    <input type="number" name="amount" id="amount" onChange={handleInput} className="form-control custom-field" required/>
                                    
                                </div>
                                <div className="col-md-6">
                                    <label className="" htmlFor="currency">
                                        {t('CURRENCY')} <span className="text-danger">*</span>
                                    </label>
                                    <select
                                        className="form-select mb-3"
                                        onChange={handleInput}
                                        name="currency"
                                        id="currency"
                                        aria-label="Default select example"
                                        value={globalAuthUser?.location.currency} 
                                    >
                                        <option value={globalAuthUser?.location.currency}>
                                        {globalAuthUser?.location.currency}
                                        </option>
                                    </select>
                                </div>
                            </div>
                            <div className="form-group row">
                                <div className="col-md-6">
                                    <label className="" htmlFor="phone_number">{t('PHONE_NUMBER')} <span className="text-danger" >*</span></label>
                                    {/* <input type="text" name="phone_number" id="phone_number" onChange={handleInput} className="form-control custom-field mb-3" required/> */}
                                    <input
                                        type="number"
                                        name="phone_number"
                                        id="phone_number"
                                        onChange={handleInput}
                                        value={params.phone_number}
                                        className={`form-control custom-field mb-1`}
                                        required  
                                        
                                    />
                                    
                                </div>
                                <div className="col-md-6">
                                    <label className="" htmlFor="receiver_type">{t('RECEIVER_TYPE')} <span className="text-danger">*</span></label>
                                    <select className="form-select mb-3" onChange={handleInput} name="receiver_type" id="receiver_type" aria-label="Default select example" required>
                                        <option value={""}>{t('SELECT_A_RECEIVER_TYPE')}</option>
                                        <option key="1" value="1">User</option>
                                        <option key="2" value="2">Agent</option>
                                    </select>
                                </div>
                            </div>
                            
                            <div className="form-group row justify-content-md-center mt-3">
                                <button type="submit" className="col-sm-2 btn btn-primary" id="apply-filter-paid" disabled = {!allowCashin ? 'disabled' : ''} data-count="0">{t('SUBMIT')}</button>
                                &nbsp;
                                <button type="reset" className="col-sm-2 btn btn-secondary" id="apply-filter-clear-paid">{t('CANCEL')}</button>
                            </div>
                            
                        </form>
                    </div>
                </div> 
            </div>

            {/** passcode modal */}
            <div>
                <GlobalConfirmPasscod
                    isOpen={isModalOpen}
                    onClose={closeModal}
                    callParentFunction={saveCicoRequest}
                />
            </div>
        </React.Fragment>

    );
}

export default CreateManualCashin;