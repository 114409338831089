import React, { useState, useEffect } from 'react'
import { useAuthUser } from 'react-auth-kit'
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from "react-router-dom";
import { setIsLoading, setShowLoading } from '../slices/auth-slice';
import { showToast } from '../slices/toast-slice';
import { useTranslation } from 'react-i18next';

function Content() {

    // const isLoading = useSelector(state => state.auth.isLoading);

    const authUser = useAuthUser()
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { t } = useTranslation();
    const globalAuthUser = useSelector((state) => state.auth.globalAuthUser);
    const [useDetails, setUserDetails] = useState(null);

    const [send, setSend] = useState([]);
    const [paid, setPaid] = useState([]);
    const [localSend, setLocalSend] = useState([]);
    const [merchantPayments, setMerchantPayments] = useState([]);
    const [submitPaid, setSubmitPaid] = useState("");

    useEffect(() => {
        if (globalAuthUser.location.agent_country.enable_submitPaid === '0'){
            setSubmitPaid(false)
        }else if (globalAuthUser.location.agent_country.enable_submitPaid === '1'){
            setSubmitPaid(true)
        }
        axios({
            method: 'get', url: process.env.REACT_APP_BNB_BASE_URL + '/api/portal/userProfileWithData',
            withCredentials: false,
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json",
                'Authorization': authUser().tokenType + ' ' + authUser().token
            }
        }).then(res => {
            setUserDetails(res.data.data)
            if(globalAuthUser.working_time_expire_warning && globalAuthUser.working_end_time){
                dispatch(showToast(["info", "", t('MSG_INFO_EXPIRING_WORKING_TIME',{time: globalAuthUser.working_end_time})]))
            }
        
        })
    }, []);

    const payNewTransaction = () => {
        // dispatch(showToast({ type: 'info', title: 'Essai', message: 'Mon message'}))
        // console.log(isLoading)
        navigate("/payTransaction")
        // navigate('/payCompleted', { replace: true, state: { 'testId': '12345' } })
    }

    const sendMoney = () => {
        navigate("/sendTransaction")
        // navigate("/send-money/ria")
    }

    const createLocalTransaction = () => {
        // navigate to the local sending component
        navigate("/sendLocalTransaction")
    }

    const payMerchant = () => {
        // navigate to the merchant payment form
        navigate("/payMerchant")
    }

    const submitPaidTransation = () => {
        navigate("/paid-transaction/create")
    }

    const loadSends = () => {
        axios.post(process.env.REACT_APP_BNB_BASE_URL + '/api/portal/countSendMoneyTxnsByParams', { }, {
            withCredentials: false,
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json",
                'Authorization': authUser().tokenType + ' ' + authUser().token
            }
        }).then(res => {
            console.log('loadSends response', res);
            setSend(res.data?.data)
        }).catch(error => {
            console.error('loadSends error', error);
        });
    }

    const loadPaids = () => {
        axios.post(process.env.REACT_APP_BNB_BASE_URL + '/api/portal/countPaidTransactionsByParams', {}, {
            withCredentials: false,
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json",
                'Authorization': authUser().tokenType + ' ' + authUser().token
            }
        }).then(res => {
            console.log('loadPaids response', res);
            setPaid(res.data?.data)
        }).catch(error => {
            console.error('loadPaids error', error);
        });
    }

    const loadLocalSends = () => {
        axios.post(process.env.REACT_APP_BNB_BASE_URL + '/api/portal/countSendMoneyTxnsByParams', { isLocalSend: 1 }, {
            withCredentials: false,
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json",
                'Authorization': authUser().tokenType + ' ' + authUser().token
            }
        }).then(res => {
            setLocalSend(res.data?.data);
        }).catch(error => {
            console.error('local sending error', error);
        });
    }

    const loadMerchantPayments = () => {
        axios.post(process.env.REACT_APP_BNB_BASE_URL + '/api/portal/merchant/paymentCount', {}, {
            withCredentials: false,
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json",
                'Authorization': authUser().tokenType + ' ' + authUser().token
            }
        }).then(res => {
            console.log('merchant payment response', res);
            setMerchantPayments(res.data?.data)
        }).catch(error => {
            console.error('merchant payment count error', error);
        });
    }

    useEffect(() => {
        loadSends()
        loadPaids()
        loadLocalSends()
        loadMerchantPayments()
    }, [])


    return (
        <>
            <div style={{ padding: "20px" }}>

                <div className='row'>
                    {
                        useDetails && useDetails?.location?.network == null &&
                        <p>
                            <span style={{ fontSize: '25px', marginRight: '30px' }}>Balance: </span> <span style={{ fontSize: '28px', fontWeight: 'bold' }}>
                                {useDetails?.location?.balance + ' ' + useDetails?.location?.currency}
                            </span>
                        </p>
                    }
                </div>

                <hr />

                <div className='row'>

                    <div className="col-12 col-sm-3" style={{ marginBottom: '15px' }}>
                        <div style={{ background: '#00a2b6', color: 'white', padding: '0', borderRadius: '5px' }}>
                            <div className="card-body" style={{ padding: '15px' }}>
                                <div>
                                    {
                                        paid != null && paid.length != 0 &&
                                        <span style={{ fontSize: '24px', fontWeight: 'bold' }}>
                                            {paid.filter(r => r.receiving_currency !== '').reduce((sum, item) => sum += item.nb_transactions, 0)}
                                        </span>
                                    }&nbsp;
                                    <span>{t('PAYMENTS')}</span> </div>
                                <div>
                                    {
                                        paid != null && paid.length != 0 &&
                                        <div>
                                            {paid.filter(r => r.receiving_currency !== '').map((r, index) => (
                                                <div key={index}>
                                                    <span style={{ fontSize: '21px', fontWeight: 'bold' }}>{parseFloat(r.total_receiving_amount).toFixed(2)}</span>&nbsp;
                                                    <span>{r.receiving_currency}</span>
                                                </div>
                                            ))}
                                        </div>
                                    }
                                </div>
                            </div>
                            <div>
                                <a className="small-box-footer" onClick={payNewTransaction}> {t("PAY_NEW_TRANSACTION")} </a>
                            </div>
                        </div>
                    </div>

                    <div className="col-12 col-sm-3" style={{ marginBottom: '15px' }}>
                        <div style={{ background: '#f64e60', color: 'white', padding: '0', borderRadius: '5px' }}>
                            <div className="card-body" style={{ padding: '15px' }}>
                                <div>
                                    {
                                        send != null && send.length != 0 &&
                                        <span style={{ fontSize: '24px', fontWeight: 'bold' }}>
                                            {send.filter(s => s.sending_currency !== '').reduce((sum, item) => sum += item.nb_transactions, 0)}
                                        </span>
                                    }&nbsp;
                                    <span>{t('SENTS')}</span>
                                </div>
                                <div>
                                    {
                                        send != null && send.length != 0 &&
                                        <div>
                                            {send.filter(s => s.sending_currency !== '').map((s, index) => (
                                                <div key={index}>
                                                    <span style={{ fontSize: '21px', fontWeight: 'bold' }}>{parseFloat(s.total_net_amount).toFixed(2)}</span>&nbsp;
                                                    <span>{s.sending_currency}</span>
                                                </div>
                                            ))}
                                        </div>
                                    }
                                </div>
                            </div>
                            <div>
                                <a className="small-box-footer" onClick={sendMoney}> {t("SEND_NEW_TRANSACTION")} </a>
                            </div>
                        </div>
                    </div>

                    <div className="col-12 col-sm-3" style={{ marginBottom: '15px'}}>
                        <div  style={{ background: '#e28743', color: 'white', padding: '0', borderRadius: '5px'}}>
                            <div className="card-body" style={{ padding: '15px'}}>
                                <div>
                                    {
                                        localSend != null && localSend.length != 0 &&
                                        <span style={{ fontSize: '24px', fontWeight: 'bold' }}>
                                            {localSend.filter(s => s.sending_currency !== '').reduce((sum, item) => sum += item.nb_transactions, 0)}
                                        </span>
                                    }&nbsp;
                                    <span>{t('SENTS')}</span>
                                </div>
                                <div> 
                                    {
                                        localSend != null && localSend.length != 0 &&
                                        <div>
                                            {
                                                localSend.filter(s => s.sending_currency !== '').map((s, index) => (
                                                <div key={index}>
                                                    <span style={{ fontSize: '21px', fontWeight: 'bold' }}>{parseFloat(s.total_net_amount).toFixed(2)}</span>&nbsp;
                                                    <span>{s.sending_currency}</span>
                                                </div>
                                            ))}
                                        </div>
                                    }
                                </div>
                            </div>
                            <div>
                                <a className="small-box-footer" onClick={createLocalTransaction}> {t("LOCAL_SENDING")} </a>
                            </div>
                        </div>
                    </div>

                    <div className="col-12 col-sm-3" style={{ marginBottom: '15px'}}>
                        <div  style={{ background: '#63913a', color: 'white', padding: '0', borderRadius: '5px'}}>
                            <div className="card-body" style={{ padding: '15px'}}>
                                <div>
                                    {
                                        merchantPayments != null && merchantPayments.length != 0 &&
                                        <span style={{ fontSize: '24px', fontWeight: 'bold' }}>
                                            {merchantPayments.filter(s => s.currency !== '').reduce((sum, item) => sum += item.nb_transactions, 0)}
                                        </span>
                                    }&nbsp;
                                    <span>{t('SENT')}</span>
                                </div>
                                <div> 
                                    {
                                        merchantPayments != null && merchantPayments.length != 0 &&
                                        <div>
                                            {
                                                merchantPayments.filter(s => s.currency !== '').map((s, index) => (
                                                <div key={index}>
                                                    <span style={{ fontSize: '21px', fontWeight: 'bold' }}>{s.total_net_amount}</span>&nbsp;
                                                    <span>{s.currency}</span>
                                                </div>
                                            ))}
                                        </div>
                                    }
                                </div>
                            </div>
                            <div>
                                <a className="small-box-footer" onClick={payMerchant}> {t("PAY_MERCHANT")} </a>
                            </div>
                        </div>
                    </div>   

                    {submitPaid && (
                    <div className="col-12 col-sm-3" style={{ marginBottom: '15px' }}>
                        <div style={{ background: '#f64e60', color: 'white', padding: '0', borderRadius: '5px' }}>
                            <div className="card-body" style={{ padding: '15px' }}>
                                <div>
                                   
                                    <span>{t('SENTS')}</span>
                                </div>
                                <div>
                                    
                                </div>
                            </div>
                            <div>
                                <a className="small-box-footer" onClick={submitPaidTransation}> {t("SUBMIT_NEW_PAID_TRANSACTION")} </a>
                            </div>
                        </div>
                    </div>   
                    )}     

                </div>

            </div>
        </>
    )
}

export default Content