import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useAuthUser } from 'react-auth-kit';
import 'datatables.net-bs5'
import 'datatables.net-responsive-bs5'
import $ from 'jquery';
import ReactDom from 'react-dom';
import { createRoot } from 'react-dom/client'
import { useSelector } from "react-redux";

const SendMoney = () => {

    const navigate = useNavigate();
    const { t } = useTranslation();

    const authUser = useAuthUser()
    const globalAuthUser = useSelector((state) => state.auth.globalAuthUser);

    useEffect(() => {
        var table = $("#datatable").DataTable({
            language: {
                sProcessing: 'Loading...'
            },
            processing: true,
            serverSide: true,
            responsive: {
                details: {
                    renderer: function (api, rowIdx, columns) {
                        var data = $.map(columns, function (col, i) {
                            return col.hidden ?
                                '<div class="row pt-2" data-dt-row="' + col.rowIndex + '" data-dt-column="' + col.columnIndex + '">' +
                                '<div class="col-sm-3"><strong>' + col.title + ':' + '</strong></div> ' +
                                '<div class="col-sm-9">' + col.data + '</div>' +
                                '</div>' :
                                '';
                        }).join('');

                        return data ?
                            $('<div/>').append(data) :
                            false;
                    },
                },

            },
            //autoWidth: true,
            lengthMenu: [[10, 50, 100, -1], [10, 50, 100, 'All']],
            pageLength: 10,
            aaSorting: [10, 'DESC'],
            ajax: {
                url: process.env.REACT_APP_BNB_BASE_URL + '/api/portal/partner/transactions',
                type: "POST",
                dataType: "json",
                headers: {
                    'Authorization': authUser().tokenType + ' ' + authUser().token
                },
                data: function (data) {
                    data.is_sent = 1;
                },
            },
            columnDefs: [
                {
                    targets: 0,
                    orderable: false,
                    className: 'dt-middle'
                },
                {
                    targets: [11], // column index
                    visible: false,
                },
                {
                    targets: [1],
                    createdCell: (td, cellData, rowData, row, col) => {
                        //Seems ReactDom.render is deprecated is React 18: must see it
                        createRoot(td).render(<button type="button" className="btn btn-link" onClick={() => navigate('/send-money/' + rowData.pickup_code)}>{rowData.pickup_code}</button>)
                    }
                },
            ],
            columns: [
                {
                    data: 'DT_RowIndex',
                    name: 'DT_RowIndex',
                },
                {
                    data: 'pickup_code',
                    name: 'pickup_code',
                },
                {
                    data: 'partner_name',
                    name: 'partner_name',
                },
                {
                    data: 'sender_name',
                    name: 'sender_name',
                },
                {
                    data: 'country',
                    name: 'country',
                },
                {
                    data: 'amount',
                    name: 'amount',
                },
                {
                    data: 'status',
                    name: 'status',
                },
                {
                    data: 'receiver_name',
                    name: 'receiver_name',
                },
                {
                    data: 'sending_total_amount',
                    name: 'sending_total_amount',
                },
                {
                    data: 'receiving_option',
                    name: 'receiving_option',
                },
                {
                    data: 'created_at',
                    name: 'created_at',
                },
                {
                    data: 'id',
                    name: 'id'
                }
            ],
        });

    }, []);

    return (
        <React.Fragment>
            <div>
                <div style={{ padding: "20px" }}>
                    <div className="card">
                        <div className="card-header">
                            <h5>{t('SENT_TRANSACTIONS')}</h5>
                        </div>
                        <div className="card-body">
                            <div>
                                {
                                    globalAuthUser?.location?.agent_country?.isoname === 'GIN' &&
                                    <div className='d-flex justify-content-end mb-4'>
                                        <button type="button" className="btn btn-primary" onClick={() => navigate('/send-money/moneygram/form-free')}>{t("MG_FORM_FREE_SEND_COMPLETION")}</button>
                                    </div>
                                }
                            </div>
                            <table id="datatable"  className="table table-bordered grid-table responsive nowrap w-100">
                                <thead>
                                    <tr>
                                        <th>#</th>
                                        <th>Reference</th>
                                        <th>Partner</th>
                                        <th>Sender</th>
                                        <th>Country</th>
                                        <th>Amount</th>
                                        <th>Status</th>
                                        <th>Beneficiary</th>
                                        <th>Total amount</th>
                                        <th>Receiving option</th>
                                        <th>Date</th>
                                    </tr>
                                </thead>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}

export default SendMoney;
