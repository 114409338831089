import React, { useState, useEffect, useRef } from "react";
import logo from "../assets/images/bnb.png";
import { useAuthHeader, useAuthUser } from "react-auth-kit";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import {
  setGlobalSearchPickupCodeResult,
  set_FORM_FREE_COMPLETION,
  set_FORM_FREE_RECEIVE_LOOKUP_RESP,
  set_REF_NO_RESP,
} from "../slices/auth-slice";
import { useLocation, useNavigate } from "react-router-dom";
import { hideLoading, showLoading } from "../slices/loading-slice";
import { useTranslation } from "react-i18next";
import { closeToast, showToast } from "../slices/toast-slice";
import { formatNumber } from "../utils/helper";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, set, useForm } from "react-hook-form";
import ReactSelect from "react-select";
import { setCurrentStep, setPaymentDetails } from "../slices/merchant-payment-slice";



function PayDSD() {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const authUser = useAuthUser();

    // const propsData = useLocation().state;
    // console.log('prop', props);

    const { t } = useTranslation();
    const LANGUAGE = useSelector((state) => state.auth.LANGUAGE);
    const globalAuthUser = useSelector((state) => state.auth.globalAuthUser);
    const [searchClicked, setSearchClicked] = useState(false);
    const [plateNumber, setPlateNumber] = useState(null);
    const [documentType, setDocumentType] = useState(null);
    const [stickerValidateData, setStickerValidateData] = useState(null);
    const [regions, setRegions] = useState([]);
    const [communes, setCommunes] = useState([]);
    const [mppTokenData, setMppTokenData] = useState(null);
    const [time, setTime] = useState(new Date());
    const selectInputRef = useRef();
    const merchantName = useSelector(state => state.merchantPayment.receiving_merchant_name);
    const rcvMerchantCode = useSelector(state => state.merchantPayment.receiving_merchant_code);
    const current_step = useSelector(state => state.merchantPayment.current_step);


    var schemaObject = {};
    schemaObject['firstName'] = yup.string().required(t("FIELD_REQUIRED"))  
    schemaObject['lastName'] = yup.string().required(t("FIELD_REQUIRED"))
    schemaObject['address'] = yup.string()
    schemaObject['phoneNumber'] = yup.string().required(t("FIELD_REQUIRED"))
    schemaObject['region'] = yup.string().required(t("FIELD_REQUIRED"))
    schemaObject['commune'] = yup.string()
    const schemaSave = yup.object().shape( schemaObject )
    var { control, register, handleSubmit: handleSubmitSave, formState: { errors }, trigger, setValue, reset } = useForm({
        resolver: yupResolver(schemaSave), mode: "onChange", reValidateMode: "onChange"
    });

    var schemaSearchObject = {};
    schemaSearchObject['mPlateNumber'] = yup.string().required(t("FIELD_REQUIRED"))
    schemaSearchObject['documentType'] = yup.string().required(t("FIELD_REQUIRED"))
    const schemaSearch = yup.object().shape( schemaSearchObject )
    var { control, register: registerSearch, handleSubmit: handleSubmitSearch, formState: { errors: errorsSearch }, trigger, setValue: seValueSearch, reset } = useForm({
        resolver: yupResolver(schemaSearch), mode: "onChange", reValidateMode: "onChange"
    });

    const onDsdSelect = (e) => {
        console.log('on click')
        const allWithClass = Array.from(
            e.currentTarget.parentElement.getElementsByClassName("card")
        );
        allWithClass.forEach((element) => {
            element.classList.remove("sCompany");
        });
        e.currentTarget.classList.add("sCompany");
    }

    
    const onSearch = (data) => {
        console.log("data", data)
        setSearchClicked(true);
        setStickerValidateData(null)
        dispatch(showLoading());
        axios({
            method: "post",
            url: process.env.REACT_APP_BNB_BASE_URL + "/api/portal/merchant/dsdVinRequest",
            withCredentials: false,
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: `${authUser().tokenType} ${authUser().token}`,
            },
            data: {
                plateNumber: plateNumber,
                country: 'gn',
                operation: 'paymentInfo',
                documentType: documentType
            },
        })
            .then((res) => {
                dispatch(hideLoading());
                if (res.data.success === true) {
                    setStickerValidateData(res.data.data)
                    if(res.data.data.status_code == 'not_paid') {
                        console.log('not_paid')
                        fetchRegions();
                    }
    
                } else {
                    dispatch(showToast(["danger", "", res.data.message]));
                }
            })
            .catch((error) => {
                dispatch(hideLoading());
                dispatch(showToast(["danger", t("SERVER_ERROR"), t("ERROR_HAS_OCCURED")]));
            });

    }

    const onSavePayment = (data) => {
        console.log('onSavePayment clicked', data)
        const countryDetails = globalAuthUser.location.agent_country;
        axios({
            method: "post",
            url: process.env.REACT_APP_BNB_BASE_URL + "/api/portal/merchant/transaction",
            withCredentials: false,
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: `${authUser().tokenType} ${authUser().token}`,
            },
            data: {
                "type": "cashin",
                "channel": "CDT",
                "amount": Number(stickerValidateData.amount) + Number(stickerValidateData.late_fee),
                "currency": countryDetails?.currency,
                "phone_number": '+224' + data.phoneNumber,
                "request_type": 0, // basic request type
                "country_code": countryDetails?.isoname,
                "request_by": 0,
                "user_id": globalAuthUser?.user_id,
                "first_name":  data.firstName,
                "last_name":  data.lastName,
                "vin": stickerValidateData.vin,
                // "vin": "AA-0019-02",
                "document_type": documentType,
                "number_plate": plateNumber,
                "commune_id": data.commune,
                "customer_id": stickerValidateData.vin,
                "rcv_merchant_code": rcvMerchantCode,
                "payment_id": stickerValidateData.payment_id
            }
        })
            .then((res) => {
                dispatch(hideLoading());
                if (res.data.status == 1) {
                    // update the payment details
                    dispatch(setPaymentDetails(res?.data?.data))

                    // show the success message to the caller
                    dispatch(showToast(['success', 'Successful Payment', t(res?.data?.message)]));

                    // update the current step
                    dispatch(setCurrentStep(current_step + 1));

                } else {
                    dispatch(showToast(["danger", "", res.data.message]));
                }
            })
            .catch((error) => {
                dispatch(hideLoading());
                console.log("error", error)
                console.log("error", error.response?.status)
                if (error.response?.data?.message != undefined) {
                    dispatch(showToast(["danger", "", t(error.response?.data?.message)]));
                } else {
                    dispatch(showToast(["danger", t("SERVER_ERROR"), t("ERROR_HAS_OCCURED")]));
                }
            });
    }

    function fetchRegions() {
        console.log('fetchRegions')
        dispatch(showLoading());
        axios({
            method: "post",
            url: process.env.REACT_APP_BNB_BASE_URL + "/api/portal/merchant/dsdVinRequest",
            withCredentials: false,
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: `${authUser().tokenType} ${authUser().token}`,
            },
            data: {
                country: 'gn',
                operation: 'listRegion',
            },
        })
            .then((res) => {
                dispatch(hideLoading());
                if (res.data.success === true) {
                    setRegions(res.data.data)
                } else {
                    dispatch(showToast(["danger", "", res.data.message]));
                }
            })
            .catch((error) => {
                dispatch(showToast(["danger", t("SERVER_ERROR"), t("ERROR_HAS_OCCURED")]));
            });
    }

    const onRegionChange = (event) => {
        console.log('event', event)
        selectInputRef.current.clearValue();
        setValue('region', event.id)
        errors.region = null
        console.log('errors', errors)
        
        // setCommunes([])
        // setDefCommune(null)
        fetchCommunes(event.id)
    }

    function fetchCommunes(regionId) {
        dispatch(showLoading());
        axios({
            method: "post",
            url: process.env.REACT_APP_BNB_BASE_URL + "/api/portal/merchant/dsdVinRequest",
            withCredentials: false,
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: `${authUser().tokenType} ${authUser().token}`,
            },
            data: {
                country: 'gn',
                operation: 'listCommune',
                regionId: regionId
            },
        })
            .then((res) => {
                dispatch(hideLoading());
                if (res.data.success === true) {
                    setCommunes(res.data.data)
                } else {
                    dispatch(showToast(["danger", "", res.data.message]));
                }
            })
            .catch((error) => {
                dispatch(hideLoading());
                console.log("error", error)
                console.log("error", error.response?.status)
                if (error.response?.data?.message != undefined) {
                    dispatch(showToast(["danger", "", error.response?.data?.message]));
                } else {
                    dispatch(showToast(["danger", t("SERVER_ERROR"), t("ERROR_HAS_OCCURED")]));
                }
            });
    }

    const onCommuneChange = (event) => {
        console.log('event', event)
        if(event != null) {
            setValue('commune', event.id)
            errors.commune = null
        }
        
    }

    async function loginToMPP() {
        dispatch(showLoading());

        try {
            const resp = await axios({
                method: "post",
                url: process.env.REACT_APP_MPP_GATEWAY_URL + "/api/auth/token",
                withCredentials: false,
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                },
                data: {
                    secret_id: process.env.REACT_APP_MPP_GATEWAY_SECRET_ID,
                    secret_key: process.env.REACT_APP_MPP_GATEWAY_SECRET_KEY
                },
            })
            dispatch(hideLoading());
            if (resp.data.success === true) {
                setMppTokenData(resp.data.data)
                return resp.data.data
            } else {
                dispatch(showToast(["danger", "", resp.data.message]));
                return null
            }
        }
        catch (error) {
            dispatch(hideLoading());
            dispatch(showToast(["danger", t("SERVER_ERROR"), t("ERROR_HAS_OCCURED")]));
            return null
        }
    }

    async function refreshTokenMPP(tokenData) {
        dispatch(showLoading());
        try {
            const resp = await axios({
                method: "post",
                url: process.env.REACT_APP_MPP_GATEWAY_URL + "/api/auth/refresh-token",
                withCredentials: false,
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization: tokenData.access_token,
                },
                data: {
                    refresh_token: tokenData.refresh_token,
                },
            })

            dispatch(hideLoading());
            if (resp.data.success === true) {
                setMppTokenData(resp.data.data)
                return resp.data.data
            } else {
                dispatch(showToast(["danger", "", resp.data.message]));
                return null
            }
        }
        catch (error) {
            dispatch(hideLoading());
            dispatch(showToast(["danger", t("SERVER_ERROR"), t("ERROR_HAS_OCCURED")]));
            return null
        }
    }

    const onPlateChange = (event) => {
        console.log('platechange', event)
        setPlateNumber(event.target.value)
    }

    return (
        <>
            <div style={{ padding: "20px" }}>
                
                <div style={{ marginBottom: "40px"}}>
                    <hr />
                    <span> { t("MERCHANT_NAME") } </span>: <span style={{ textTransform: "uppercase", fontSize: '18px', fontFamily: 'verdana', fontWeight: 'bold' }}> { merchantName } </span>
                    <hr />
                </div>



                <div className="row justify-content-center">
                {/* <p>The current time is: {time.toLocaleTimeString()}</p>; onChange={(e) => setPlateNumber(e.target.value)}  */}
                    <form onSubmit={handleSubmitSearch(onSearch)} style={{ maxWidth: "1000px", margin: "auto", marginBottom: "15px" }}>
                        <div className="row"  >
                            <div className="col-md-5 mb-3">
                                <label htmlFor="mPlateNumber" className="form-label" > {t("PLATE_NUMBER")} </label>
                                <input type="text" className="form-control" id="mPlateNumber"   {...registerSearch("mPlateNumber", { onChange: (e) => { onPlateChange(e) }})}  />
                                {/* {searchClicked && !plateNumber && (<p className="input-error-msg" style={{ marginBottom: "1px" }} >  {t("THIS_FIELD_IS_REQUIRED")} </p>)} */}
                                <p className="input-error-msg"> {errorsSearch.mPlateNumber?.message} </p>

                            </div>
                            <div className="col-md-5 mb-3">
                                <label htmlFor="documentType" className="form-label" > {t("DOCUMENT_TYPE")} </label>
                                <select style={{ maxWidth: "100%" }} className="form-select" name="documentType" id="documentType"   {...registerSearch("documentType", { onChange:(e) => setDocumentType(e.target.value) })}   >
                                    <option value="">{t("SELECT")}</option>
                                    <option value="vignette" key="0">Vignette</option>
                                    <option value="autorisation" key="1">Autorisation</option>
                                </select>
                                {/* <input type="text" className="form-control" id="documentTypeSearch" onChange={(e) => setPlateNumber(e.target.value)} /> */}
                                {/* {searchClicked && !plateNumber && (<p className="input-error-msg" style={{ marginBottom: "1px" }} >  {t("THIS_FIELD_IS_REQUIRED")} </p>)} */}
                                <p className="input-error-msg"> {errorsSearch.documentType?.message} </p>

                            </div>
                            <div className="col-md-2 mb-3" style={{ display: "flex", alignItems: "flex-end"}} >
                                <button type="submit" style={{  width: "200px", marginBottom: "5px" }} className="btn btn-primary"  > {t("SEARCH")} </button>
                            </div>
                        </div>
                        
                    </form>
                </div>

                {/* plate details */}
                { stickerValidateData != null &&  <div>
                    <hr />
                    <div className="row" style={{ padding: "10px 1px" }}>
                        <div className="col-md-6 row">
                            <span className="col-md-4 key">{t("PLATE_NUMBER")}</span>
                            <span className="col-md-8 value"> { stickerValidateData.number_plate } </span>
                        </div>
                        <div className="col-md-6 row">
                            <span className="col-md-4 key">{t("VIN")}</span>
                            <span className="col-md-8 value"> { stickerValidateData.vin } </span>
                        </div>
                    </div>
                    <div className="row odds" style={{ padding: "10px 1px" }}>
                        <div className="col-md-6 row">
                            <span className="col-md-4 key">{t("BRAND_MODEL")}</span>
                            <span className="col-md-8 value"> { stickerValidateData.brand + ' / ' + stickerValidateData.model } </span>
                        </div>
                        <div className="col-md-6 row">
                            <span className="col-md-4 key">{t("CATEGORY_TYPE")}</span>
                            <span className="col-md-8 value"> { stickerValidateData.category + ' / ' + stickerValidateData.type  } </span>
                        </div>
                    </div>
                    <div className="row" style={{ padding: "10px 1px" }}>
                        <div className="col-md-6 row">
                            <span className="col-md-4 key">{t("TAX_STICKER_NAME")}</span>
                            <span className="col-md-8 value"> { stickerValidateData.tax_sticker_name } </span>
                        </div>
                        <div className="col-md-6 row">
                            <span className="col-md-4 key">{t("AMOUNT")}</span>
                            { stickerValidateData.late_fee == 0 ? <span className="col-md-8 value"> { formatNumber(stickerValidateData.amount, 2) } </span> 
                                : <span className="col-md-8 value"> { formatNumber(stickerValidateData.amount, 2) + ' GNF' + ' + ' + t("PENALITY") + ' (' + formatNumber(stickerValidateData.late_fee, 2) + ' GNF)' } </span> }
                        </div>
                    </div>
                    
                </div>
                }

                {
                    stickerValidateData != null && stickerValidateData?.status_code == 'paid' &&
                    <div>
                        <hr />
                        <div class="alert alert-success" style={{ textAlign: 'center'}} role="alert">
                            {t('VIN_ALREADY_PAID')}
                        </div>
                    </div>
                }

                {
                    stickerValidateData != null && stickerValidateData?.status_code == 'not_paid' &&
                    <div>
                        <hr />
                        <form onSubmit={handleSubmitSave(onSavePayment)} style={{ maxWidth: "900px", margin: 'auto' }}>
                            <div className="row marg-btm" style={{ paddingBottom: "8px"}}>
                                <div className="col-md-6">
                                    <label className="col-form-label" htmlFor="region">{t("REGION")}</label> <span style={{ color: "red" }}>*</span>
                                    
                                    <ReactSelect
                                        placeholder={ t("SELECT_THE_REGION") }
                                        styles={{
                                            control: (baseStyles, state) => ({
                                                ...baseStyles, border: "2px solid #ced4da",
                                                //   borderColor: state.isFocused ? 'grey' : 'red',
                                                padding: '2px',  borderBottomLeftRadius: "0px", borderBottomLeftRadius: "0px"
                                            }),
                                        }}
                                        options={regions}
                                        formatOptionLabel={(region) => (
                                            <div>
                                                <span> { region.region } </span>
                                            </div>
                                        )}
                                        getOptionValue={region => `${region.id}`}
                                        getOptionLabel={region => `${region.region}`}
                                        onChange={onRegionChange}
                                        register = {{...register("region") }}
                                    />
                                    <p className="input-error-msg"> {errors.region?.message} </p>
                                </div>
                                <div className="col-md-6">
                                    <label className="col-form-label" htmlFor="commune">{t("COMMUNE")}</label> <span style={{ color: "red" }}>*</span>
                                    <ReactSelect
                                        ref={selectInputRef}
                                        placeholder={ t("SELECT_THE_COMMUNE") }
                                        styles={{
                                            control: (baseStyles, state) => ({
                                                ...baseStyles, border: "2px solid #ced4da",
                                                //   borderColor: state.isFocused ? 'grey' : 'red',
                                                padding: '2px',  borderBottomLeftRadius: "0px", borderBottomLeftRadius: "0px"
                                            }),
                                        }}
                                        options={communes}
                                        formatOptionLabel={(commune) => (
                                            <div>
                                                <span> { commune.commune } </span>
                                            </div>
                                        )}
                                        getOptionValue={commune => `${commune.id}`}
                                        getOptionLabel={commune => `${commune.commune}`}
                                        register = {{...register("commune") }}
                                        onChange={onCommuneChange}
                                    />
                                    <p className="input-error-msg"> {errors.commune?.message} </p>
                                </div>
                            </div>
                            <div className="row marg-btm odds">
                                <div className="col-md-6">
                                    <label className="col-form-label" htmlFor="firstName">{t("FIRSTNAME")}</label> <span style={{ color: "red" }}>*</span>
                                    <input type="text" id="firstName" className='form-control'  {...register("firstName")} />
                                    <p className="input-error-msg"> {errors.firstName?.message} </p>
                                </div>
                                <div className="col-md-6">
                                    <label className="col-form-label" htmlFor="lastName">{t("LASTNAME")}</label> <span style={{ color: "red" }}>*</span>
                                    <input type="text" id="lastName" className='form-control'  {...register("lastName")} />
                                    <p className="input-error-msg"> {errors.lastName?.message} </p>
                                </div>
                            </div>
                            <div className="row marg-btm" style={{ paddingBottom: "8px"}}>
                            <div className="col-md-6">
                                    <label className="col-form-label" htmlFor="phoneNumber">{t("PHONE_NUMBER")}</label> <span style={{ color: "red" }}>*</span>
                                    <div className="input-group col-8" style={{ width: "100%" }}>
                                        <span className="input-group-text" id="basic-addon1"> {globalAuthUser?.location?.agent_country.phone_code} </span>
                                        <input type="text" id="phoneNumber" className='form-control' aria-describedby="basic-addon1" {...register("phoneNumber")} />
                                    </div>
                                    <p className="input-error-msg"> {errors.phoneNumber?.message} </p>
                                </div>
                                <div className="col-md-6">
                                    <label className="col-form-label" htmlFor="address">{t("ADDRESS")}</label>
                                    <input type="text" id="address" className='form-control'  {...register("address")} />
                                    <p className="input-error-msg"> {errors.address?.message} </p>
                                </div>
                            </div>
                            
                            <hr />
                            <div style={{ maxWidth: "400px", margin: 'auto', textAlign: "center" }}>
                                <div>
                                    <span> { t('AMOUNT_TO_PAY') } </span> : <span style={{ fontWeight: "bold"}}> { formatNumber((Number(stickerValidateData.amount) + Number(stickerValidateData.late_fee)), 2)  + ' GNF'} </span>
                                </div>
                                <hr />
                                <div style={{ width: "100%" }}>
                                    <button type='submit' className="btn btn-success"> {t("SAVE_PAYMENT")}  </button> &nbsp;
                                </div>
                            </div>
                        </form>
                    </div>
                }


                  
            </div>
        </>
    );

}

export default PayDSD;